body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  font-size: 16px;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: 100vw;
  height: 100vh;
}

code {
  font-family: Monaco, Consolas, 'Courier New',
    monospace;
}

.ui.dimmer {
  background: rgba(0, 0, 0, 0.60);
}

.ui.modal>.close {
  top: 1.0535rem;
  right: 1rem;
  color: rgba(0, 0, 0, .87);
}

.ui.button {
  padding: 8px 12px;
  font-size: inherit;
}

.ui.selection.dropdown {
  min-width: 10em;
  min-height: 2em;
  padding: 0.5em 1em;
}

.ui.selection.dropdown>.dropdown.icon {
  padding: 0.5em 1em;
}

.ui.selection.dropdown .menu .item {
  min-height: 2em;
  padding: 0.5em 1em !important;
}

.ui.form input[type=text] {
  min-height: 2em;
  padding: 0.5em 1em;
}

.ui.search.selection.dropdown>input.search {
  min-height: 2em;
  padding: 0.35em 1em;
  line-height: unset;
}

.ui.input input {
  min-height: 2em;
  padding: 0.45em 1em;
}

.header {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  box-shadow: 0 0 5px black;
}

.header-content {
  width: 100%;
  max-width: 1000px;
  min-height: 50px;
  display: flex;
  align-items: center;
}

.header-title {
  font-weight: bold;
  margin: 0 20px 0 10px;
  width: 80px;
  user-select: none;
}

.header-nav {
  margin: 10px 0;
  flex-grow: 1;
  display: flex;
  flex-wrap: wrap;
}

.header-nav-item {
  margin-right: 10px;
}

.footer {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 5px black;
  min-height: 50px;
}

.ui.button.header-logout {
  margin-right: 10px;
}

.link {
  cursor: pointer;
}

.container {
  flex-grow: 1;
  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-height: 200px;
  overflow-y: auto;
}

.container>div {
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.teams {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 18px;
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  border: 1px solid black;
  padding: 5px 5px;
  cursor: pointer;
  min-width: 110px;
  font-size: 14px;
  color: black;
  text-decoration: none;
}

.team:hover {
  color: black;
  text-decoration: underline;
}

.team>img {
  width: 45px;
}

.game-group {
  margin-bottom: 15px;
}

.games {
  flex: 1;
}

.game {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid black;
  margin-bottom: 8px;
  padding: 5px 10px;
  cursor: pointer;
  flex-wrap: wrap;
  gap: 10px;
}

.game-date {
  display: flex;
  align-items: center;
  width: 90px;
}

.game-opponent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: bold;
  width: 75px;
}

.game-opponent>img {
  width: 40px;
}

.game-roster {
  width: 94px;
}

.game-roster-error {
  color: red;
}

.game-changes {
  width: 164px;
  color: #FF9800;
}

.upcoming-games {
  margin-top: 20px;
  max-width: 700px;
}

.upcoming-game {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid black;
  margin-bottom: 8px;
  padding: 5px 10px;
  flex-wrap: nowrap;
  gap: 10px;
}

.upcoming-game-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: 5px;
}

.upcoming-game-info>a>img {
  width: 50px;
}

.roster-title {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.roster-title:first-child {
  margin-top: 0px;
}

.roster-title-text {
  font-size: 20px;
  font-weight: bold;
  margin-right: 10px
}

.roster-title>i.filter.icon {
  cursor: pointer;
}

.roster-group {
  margin-top: 10px;
}

.roster-group-title {
  margin-bottom: 0.28rem;
  font-weight: 700;
}

.roster-item {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 5px;
  gap: 10px;
}

.roster-jersey-number {
  width: 30px;
}

.roster-player {
  width: 140px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.roster-notes {
  flex-grow: 1;
}

@media only screen and (max-width: 767.98px) {
  .roster-item {
    padding-bottom: 8px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 8px;
  }

}